<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container :title="$route.name" :exportInfo="currItems">
      <template v-slot:bottom-header>
        <v-row class="mx-4">
          <v-col cols="12" md="2">
            <v-select v-model="filters.month" :items="catalogues.months" label="Mes" item-text="text" item-value="value" prepend-icon="mdi-calendar" persistent-hint hide-details dense outlined></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-select v-model="filters.day" :items="catalogues.days" label="Día" prepend-icon="mdi-calendar" persistent-hint hide-details dense outlined></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-select v-model="filters.anniversary" :items="[5, 10, 15, 20, 25, 30, 35, 40, 45, 50]" label="Aniversario" prepend-icon="mdi-star" persistent-hint hide-details dense outlined></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete v-model="filters.year" :items="catalogues.years" label="Año de cálculo" prepend-icon="mdi-history" persistent-hint hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete v-model="filters.users" :items="catalogues.users" item-text="nombreCompleto" item-value="idusuario" label="Empleado" prepend-icon="mdi-account"
            multiple persistent-hint hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12" md="5">
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
          </v-col>
          <v-col cols="10" md="1">
            <Basic-Btn text="Reiniciar" color="blue-grey" dark icon="mdi-filter-remove" @click="clear()" :loading="loading" />
          </v-col>
          <v-col cols="10" md="1">
            <Basic-Btn text="Buscar" color="success" icon="mdi-magnify" @click="refresh()" :loading="loading" />
          </v-col>
        </v-row>
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :search="search" @current-items="getFiltered"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="itemsPerPage" item-key="no_empleado" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services"
import { Loader, BasicAlert, TableContainer, BasicBtn, DatePicker } from "@/components"
import moment from 'moment'

export default {
  name: "anniversary",
  components: {
    Loader,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DatePicker,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    catalogues: {
      years: [],
      months: [
        { text: 'Enero', value: '01' },
        { text: 'Febrero', value: '02' },
        { text: 'Marzo', value: '03' },
        { text: 'Abril', value: '04' },
        { text: 'Mayo', value: '05' },
        { text: 'Junio', value: '06' },
        { text: 'Julio', value: '07' },
        { text: 'Agosto', value: '08' },
        { text: 'Septiembre', value: '09' },
        { text: 'Octubre', value: '10' },
        { text: 'Noviembre', value: '11' },
        { text: 'Diciembre', value: '12' }
      ],
      days: [],
      anniversaries: [],
      users: []
    },
    headers: [ 
      { text: "Número de empleado", align: "left", sortable: true, value: "no_empleado" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre" },
      { text: "Apellido paterno", align: "left", sortable: true, value: "apellido_paterno" },
      { text: "Apellido materno", align: "left", sortable: true, value: "apellido_materno" },
      { text: "", align: " d-none", sortable: true, value: "nombre_completo" },
      { text: "Puesto", align: "left", sortable: true, value: "puesto" },
      { text: "Departamento", align: "left", sortable: true, value: "departamento" },
      { text: "Fecha de ingreso", align: "left", sortable: true, value: "fecha_ingreso" },
      { text: "Días de antigüedad", align: "left", sortable: true, value: "dias_antiguedad" },
      { text: "Años de antigüedad", align: "left", sortable: true, value: "antiguedad" },
    ],
    items: [],
    currItems: [],
    itemsPerPage: 20,
    users: [],
    filters: {
      year: '',
      month: '',
      day: '',
      anniversary: '',
      users: ''
    },
  }),
  watch: {
    search(val) {
      this.itemsPerPage = (val) ? -1 : 20
    }
  },
  mounted() {
    this.fillFilters()
    this.getUser()
    this.refresh()
  },
  methods: {
    fillFilters() {
      let year = parseInt(moment().format('YYYY'))
      for (let i = 2100; i >= 1980; i--) {
        this.catalogues.years.push(i.toString())
      }
      this.filters.year = year.toString()
      for (let i = 0; i < 31; i++) {
        this.catalogues.days.push((i + 1).toString())
      }
    },
    clear() {
      let year = moment().format('YYYY')
      this.filters = {
        year: year,
        month: '',
        day: '',
        anniversary: '',
        users: ''
      }
      this.refresh()
    },
    refresh() {
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.filters))
      this.axios
        .post(services.routes.reports + "/anniversary", { data })
        .then((response) => {
          this.items = response.data.data
          for (let i = 0; i < this.items.length; i++) {
            this.items[i].nombre_completo = this.items[i].nombre + ' ' + this.items[i].apellido_paterno + ' ' + this.items[i].apellido_materno
          }
        })
        .catch((error) => {
          this.alert.active = true
          this.alert.color = "error"
          this.alert.msg = error.response.data.message || "Servicio no disponible"
        })
        .finally(() => {
          this.loading = false
          this.alert.active = true
          this.alert.color = "success"
          this.alert.msg = "Registros obtenidos correctamente."
        });
    },
    getUser() {
      this.loading = true;
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.catalogues.users = response.data.data;
          for (let i = 0; i < this.catalogues.users.length; i++) {
            if (this.catalogues.users[i].fecha_borrado) {
              this.catalogues.users.splice(i, 1)
              i--
            }
          }
          for (let i = 0; i < this.catalogues.users.length; i++) {
            this.catalogues.users[i].nombreCompleto =
              (this.catalogues.users[i].detalles_usuarios[0].no_empleado ? this.catalogues.users[i].detalles_usuarios[0].no_empleado : "") +
              (this.catalogues.users[i].detalles_usuarios[0].nombre ? " " + this.catalogues.users[i].detalles_usuarios[0].nombre : "") +
              (this.catalogues.users[i].detalles_usuarios[0].apellido_paterno ? " " + this.catalogues.users[i].detalles_usuarios[0].apellido_paterno : "") +
              (this.catalogues.users[i].detalles_usuarios[0].apellido_materno ? " " + this.catalogues.users[i].detalles_usuarios[0].apellido_materno : "")
          }
        })
        .catch((error) => {
          this.alert.active = true
          this.alert.color = "error"
          this.alert.msg = error.response.data.message || "Servicio no disponible"
        })
        .finally(() => {
          this.loading = false
        });
    },
    getFiltered(values) {
      this.currItems = values
    }
  },
};
</script>

<style scoped>
</style>
